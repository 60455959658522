<template>
  <v-container class="white d-flex flex-column align-center py-14">
    <v-row
      ><v-col><v-icon color="success" dark size="100px">mdi-checkbox-marked-circle</v-icon></v-col>
    </v-row>
    <v-row>
      <v-col><p>Sprawdź swoją skrzynkę pocztową.</p></v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'PasswordResetSent',
  data() {
    return {
      isActivated: false,
    };
  },
};
</script>
<style lang="scss" scoped></style>
